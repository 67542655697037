import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import ko from "date-fns/locale/ko";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../datepicker/datepicker.scss";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
registerLocale("ko", ko);
setDefaultLocale(ko);

const CustomInput = forwardRef(({ value, onClick, className }, ref) => (
  <input type="text" value={value} readOnly className={className} onClick={onClick} ref={ref} />
));

function DatePickerCustom({ data, name = '', onChangeDatePicker, minDate, maxDate, readOnly }) {
  const setWeekendColor = (date) => {
    return date.getDay() === 0 || date.getDay() === 6 ? "color_red" : "";
  };
  const range = (start, end) => {
    return new Array(end - start).fill().map((d, i) => i + start);
  };
  const years = range(1900, getYear(new Date()) + 20, 1);
  const months = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];

  return (
    <DatePicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="date_header">
          <div className="select_boxes">
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
              style={{
                width: 70,
                height: 24,
                margin: 0,
                marginRight: 15,
                padding: 0,
                paddingLeft: 10,
                border: "none",
                zIndex: 100,
              }}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              value={months[months.indexOf(getMonth(date) + 1 + "월")]}
              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
              style={{
                width: 60,
                height: 24,
                margin: 0,
                padding: 0,
                paddingLeft: 5,
                border: "none",
              }}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <button className="btn_date_next" onClick={increaseMonth} disabled={nextMonthButtonDisabled} />
        </div>
      )}
      selected={Number(data) || ""}
      onChange={onChangeDatePicker}
      shouldCloseOnSelect={true}
      customInput={<CustomInput />}
      dayClassName={(date) => setWeekendColor(date)}
      minDate={minDate}
      maxDate={maxDate}
      name={name}
      readOnly={readOnly}
      dateFormat="yyyy-MM-dd"
      popperModifiers={{
        preventOverflow: {
          enabled: true,
        },
      }}
    />
  );
}

export default DatePickerCustom;
