import React, { useEffect, useState } from "react";
import Styles from "./tabsNavigator.module.scss";
import CHECKPOINT_API from "../../../../../API/patent/checkpoint";

const TabsNavigator = () => {
  const [activeTab, setactiveTab] = useState(0);
  const [activeAccordionItems, setActiveAccordionItems] = useState([]);

  const toggleAccordionItem = (index) => {
    setActiveAccordionItems((pervItems) =>
      pervItems.includes(index) ? pervItems.filter((itemIndex) => itemIndex !== index) : [...pervItems, index]
    );
  };

  const changeTabHandler = (name, index) => {
    setactiveTab({ name, index });
    setActiveAccordionItems([]);
  };

  // API logic
  const [frequentQuestionsState, setFrequentQuestionsState] = useState({
    state: "LOADING",
    questions: [],
  });

  useEffect(() => {
    CHECKPOINT_API.getFrequentQuestions()
      .then((result) => {
        const { checkpointList } = result.data.data;
        setFrequentQuestionsState({
          state: "SUCCESS",
          questions: checkpointList,
        });
        setactiveTab({
          name: Object.keys(checkpointList)[0],
          index: 0,
        });
      })
      .catch((err) => {
        setFrequentQuestionsState({
          state: "ERROR",
          error: err?.response.data?.message ?? err.message,
        });
      });
  }, []);

  return (
    <div className={Styles["tabs-navigator"]}>
      {frequentQuestionsState.state === "LOADING" ? null : frequentQuestionsState.state === "SUCCESS" ? (
        <>
          <ul>
            {Object.keys(frequentQuestionsState.questions)?.map((tabName, tabIndex) => (
              <li
                onClick={() => changeTabHandler(tabName, tabIndex)}
                style={{ backgroundColor: activeTab.name === tabName ? "#fff7f4" : "" }}
                key={tabName}
              >
                {tabName}
              </li>
            ))}
          </ul>
          <ul
            style={{
              borderTopLeftRadius: activeTab.index === 0 ? 0 : "24px",
              borderTopRightRadius: activeTab.index === 2 ? 0 : "24px",
            }}
          >
            {frequentQuestionsState.questions[activeTab.name]?.map(({ idx, answer, question }) => (
              <li key={idx}>
                <div onClick={() => toggleAccordionItem(idx)}>
                  <p>{question}</p>
                  <i
                    style={{ rotate: activeAccordionItems.includes(idx) ? "90deg" : "" }}
                    className="icon_arrow_right_s"
                  />
                </div>
                <div style={{ gridTemplateRows: activeAccordionItems.includes(idx) ? "1fr" : "0fr" }}>
                  <p>{answer}</p>
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div className={Styles["error-div"]}>{frequentQuestionsState.error}</div>
      )}
    </div>
  );
};

export default TabsNavigator;
