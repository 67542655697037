import React, { useEffect, useState } from "react";
import Styles from "./checkpointReport.module.scss";
import CHECKPOINT_API from "../../../../../API/patent/checkpoint";

const CheckpointReport = ({ onClose, type }) => {
  const [reportState, setReportState] = useState({
    state: "LOADING",
    questions: [],
  });

  useEffect(() => {
    CHECKPOINT_API.getReport()
      .then((result) => {
        const { checkpointList } = result.data.data;
        setReportState({
          state: "SUCCESS",
          questions: checkpointList,
        });
      })
      .catch((err) => {
        setReportState({
          state: "ERROR",
          error: err?.response.data?.message ?? err.message,
        });
      });
  }, []);

  return (
    <div className={Styles["checkpoint-report"]}>
      <header>
        <h3>Check Point</h3>
        <i onClick={onClose} className="icon_exit_white"></i>
      </header>

      {reportState.state === "LOADING" ? (
        <span className={`spinner ${Styles["loading"]}`} />
      ) : reportState.state === "SUCCESS" ? (
        <ul>
          {reportState.questions[type]?.map(({ idx, question }) => (
            <li key={idx}>
              <i className="icon_check_circle"></i>
              <p>{question}</p>
            </li>
          ))}
        </ul>
      ) : (
        <div>{reportState.error.message}</div>
      )}
    </div>
  );
};

export default CheckpointReport;
