import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./css/AnnualFeeList.scss";
import AnnualPay from "../../API/annualPay";
import MemberContext from "../../store/Member";
import AnnualFeeReview from "./AnnualFeeReview";
import AnnualFeeReviewSecond from "./AnnualFeeReviewSecond";
import Pagination from "../common/Pagination";
import AnnualFeeReceipt from "./AnnualFeeReceipt";
import Loading from "../loading/Loading";
import PaymentState from "./PaymentState";
import ToggleButtonWithSpan from "../common/ToggleButtonWithSpan";
import CheckBox from "../common/CheckBox";
import AnnualFeeReviewState from "./AnnualFeeReviewState";
import AnnualFeeGiveUpConfirm from "./AnnualFeeGiveUpConfirm";
import CommonAPI from "../../API/common";
import AnnualFeeReport from "./AnnualFeeReport";
import AnnualFeePerCost from "./AnnualFeePerCost";
import AnnualFeeMailing from "./AnnualFeeMailing";
import MemberAPI from "../../API/member";
import ReportButton from "./ReportButton";

const searchSelectOption = [
  {
    key: 0,
    name: "출원번호",
    value: "출원번호",
  },
  {
    key: 1,
    name: "등록번호",
    value: "등록번호",
  },
  {
    key: 2,
    name: "관리번호",
    value: "관리번호",
  },
];

function AnnualFeeList({ history, location }) {
  let tab_tags = [],
    table_head_tag = [];
  const time = new Date().getTime();
  const nowYear = new Date().getFullYear();
  const nowMonth = new Date().getMonth() + 1;
  const { year } = useParams();
  const payload =
    JSON.parse(window.localStorage.getItem(idx + "_annualPay") || null) || {};
  const { idx } = useContext(MemberContext);
  const [patentTag, setPatentTag] = useState([]);
  const [pastView, setPastView] = useState({
    patent: false,
    trademark: false,
    design: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [tradeMarkTag, setTradeMarkTag] = useState([]);
  const [patentNationArr, setPatentNationArr] = useState([]);
  const [patentJointArr, setPatentJointArr] = useState([]);
  const [patentManagerNameArr, setPatentManagerNameArr] = useState([]);
  const [tradeMarkNationArr, setTradeMarkNationArr] = useState([]);
  const [selectNation, setSelectNation] = useState({
    patent_nation: "",
    tradeMark_nation: "",
  });
  const [selectJoint, setSelectJoint] = useState("");
  const [selectManager, setSelectManager] = useState(
    window.sessionStorage.getItem("ip_member_name") || ""
  );
  const [designTag, setDesignTag] = useState([]);
  const [designNationArr, setDesignNationArr] = useState([]);

  const [totalCount, setTotalCount] = useState({
    patent_count: 0,
    tradeMark_count: 0,
    design_count: 0,
  });
  const [showPopup, setShowPopup] = useState({
    first: false,
    second: false,
    receipt: false,
  });
  const [annualFeeData, setAnnualFeeData] = useState({
    manage_number: "",
    annual_pay_idx: "",
    patent_idx: "",
    trademark_idx: "",
    design_idx: "",
  });

  const [curPage, setCurPage] = useState(
    window.sessionStorage.getItem("curPage")
  );
  const [showPatent, setShowPatent] = useState(
    window.sessionStorage.getItem("showPatent") || "patent"
  );
  const [currentYear, setCurrentYear] = useState(
    window.sessionStorage.getItem("currentYear") || year
  );
  const [month, setMonth] = useState(
    window.sessionStorage.getItem("month") || nowMonth
  );

  const [last, setLast] = useState(1);
  const [tabState, setTabState] = useState(false);
  const [count, setCount] = useState(10);
  // tab 설정 state
  // text: checkbox 이름, key: api 통신할 때의 key 값, checked: true인 값이 table_head에 추가됨(초기 값이 기본 체크 값이 됨), defaultChecked: 초기화를 위해 기본 체크값이 어떤 거였는지 알기 위해 정의한 값,
  // 초기 checked 1인 값이 테이블 기본 head 값이 됨.
  const [btns_state_tab, setBtnsStateTab] = useState([]);
  const [table_head, setTableHead] = useState([]); // 테이블 헤더값
  const [data, setData] = useState({});
  const [selectedBasics, setSelectedBasics] = useState(true); // Tab 기본으로 설정 on / off
  const [isShowCompanyIdxList, setIsShowCompanyIdxList] = useState([]);
  const [isShowing, setIsShowing] = useState(location.state); // 연차료 기능을 사용하는 대학
  const [isShowReport, setIsShowReport] = useState(false); // 일단 리포트 한양대만 보이게 하기 위해서 사용하는 변수
  const [isShowMail, setIsShowMail] = useState(false);
  const [showGiveUpPop, setShowGiveUpPop] = useState(false);
  const [moreTab, setMoreTab] = useState({
    nation: true, // 국가
    jointApplicant: true, // 공동출원인
    manager: true, // IP담당자
  });
  const [isShowingUpdated, setIsShowingUpdated] = useState(false);
  const [mouse, setMouse] = useState(false);
  const [isReportShow, setIsReportShow] = useState(false);
  const [isCompFrameShow, setIsCompFrameShow] = useState(false);
  const [isShowPerCost, setIsShowPerCost] = useState(false); // 관리주체/비용분담률 On/Off State
  const [isShowMailPopUp, setIsShowMailPopUp] = useState(false);

  const [searchOption, setSearchOption] = useState(0); // 검색 옵션 확인용
  const [searchKeyword, setSearchKeyword] = useState(""); // 검색 키워드
  const [company_idx, setCompanyIdx] = useState(null);

  window.sessionStorage.setItem("curPage", curPage);
  window.sessionStorage.setItem("showPatent", showPatent);
  window.sessionStorage.setItem("currentYear", currentYear);
  window.sessionStorage.setItem("month", month);

  payload.year = year;
  payload.month = month;

  const AnnualPayReceipt = ({ item }) => {
    return item.billing_idx !== null ? (
      <div className="annual_receipt">
        <p>비용청구</p>
        <button
          onClick={() => {
            let data = {
              ...item,
              nation: item.nation_code,
              annual_pay_idx: item.annual_pay_idx,
            };

            switch (showPatent) {
              case "patent":
                data.patent_idx = item.idx;
                break;
              case "trademark":
                data.trademark_idx = item.idx;
                break;
              case "design":
                data.design_idx = item.idx;
                break;
            }
            setAnnualFeeData(data);
            setShowPopup({ ...showPopup, billing: false, receipt: true });
            /*setAnnualFeeData({
              annual_pay_idx: item.annual_pay_idx,
              ...(showPatent
                ? { patent_idx: item.idx }
                : { trademark_idx: item.idx }),
            });
            setShowPopup({ ...showPopup, receipt: true });*/
          }}
        >
          영수증확인하기
        </button>
      </div>
    ) : (
      <p>-</p>
    );
  };
  useEffect(() => {
    MemberAPI.getInfo().then((res) => {
      const data = res.data;
      setCompanyIdx(data.company_idx);
    });
  }, []);

  useEffect(() => {
    const doUpdate = async () => {
      await updateIsShowing();
      setIsShowingUpdated(true); // Set this state to true after updateIsShowing is done
    };

    doUpdate();
  }, []); // Empty dependency array if you want this to run only once

  useEffect(() => {
    if (isShowingUpdated) {
      switch (showPatent) {
        case "patent":
          getPatentList();
          break;
        case "trademark":
          getTradeMarkList();
          break;
        // 여기에 다른 탭에 대한 조건을 추가할 수 있습니다.
        case "design":
          getDesignList();
          break;
        default:
          getPatentList();
          break;
      }
    }
  }, [
    isShowingUpdated,
    idx,
    curPage,
    month,
    showPatent,
    selectNation,
    pastView,
    selectJoint,
    selectManager,
    currentYear,
    count,
  ]);

  useEffect(() => {
    initData(data.items);
  }, [data, btns_state_tab]);

  const handleSearch = () => {
    switch (showPatent) {
      case "patent":
        getPatentList();
        break;
      case "trademark":
        getTradeMarkList();
        break;
      // 여기에 다른 탭에 대한 조건을 추가할 수 있습니다.
      case "design":
        getDesignList();
        break;
      default:
        getPatentList();
        break;
    }
  };

  const downloadPatentlist = (item) => {
    let payload = {
      year: currentYear,
      month: month,
      count: count,
      page: curPage,
      nation: selectNation.patent_nation,
      past: Number(pastView.patent),
      joint_applicant_idx: selectJoint,
      ip_member_name: selectManager,
      keyword: searchKeyword,
      option: searchOption,
      company_name: sessionStorage.getItem("company_name"),
      giveup: 2,
      excel: 1,
      korea: 1,
    };
    AnnualPay.getDownloadPatentList(payload);
  };

  const onChangeYear = (e) => {
    setCurPage(1);
    //setPatentNationArr([]);
    //setPayStateArr([]);
    setCurrentYear(Number(e.target.value));
  };

  const onChangeMonth = (e) => {
    setCurPage(1);
    //setPatentNationArr([]);
    //setPayStateArr([]);
    setMonth(Number(e.target.value));
  };

  const updateIsShowing = async () => {
    const res = await CommonAPI.getAnnualPayUsableList();
    const companyIdx = window.sessionStorage.getItem("company_idx");

    const annualPayShow = res.data.companyList.some(
      (item) => item.company_idx === companyIdx && item.patent_usable === 1
    );

    const annualPayReportShow = res.data.companyList.some(
      (item) => item.company_idx === companyIdx && item.patent_report_use === 1
    );

    const annualPayMailShow = res.data.companyList.some(
      (item) => item.company_idx === companyIdx && item.email_service === 1
    );

    setIsShowing(annualPayShow);
    setIsShowReport(annualPayReportShow);
    setIsShowMail(annualPayMailShow);
  };

  const getPatentList = () => {
    if (!idx) return;

    if (window.localStorage.getItem("annual_patent_tab_init")) {
      window.localStorage.setItem("annual_patent_total_number", "1");
      window.localStorage.setItem("annual_patent_manage_number", "0");
      window.localStorage.setItem("annual_patent_family", "0");
      window.localStorage.setItem("annual_patent_sm_grade", "0");
      window.localStorage.setItem("annual_patent_now_grade", "0");
      window.localStorage.setItem("annual_patent_nation", "1");
      window.localStorage.setItem("annual_patent_apply_number", "1");
      window.localStorage.setItem("annual_patent_apply_at", "1");
      window.localStorage.setItem("annual_patent_register_number", "1");
      window.localStorage.setItem("annual_patent_register_at", "1");
      window.localStorage.setItem("annual_patent_annual_payment_deadline", "1");
      window.localStorage.setItem("annual_patent_cost", "1");
      window.localStorage.setItem("annual_patent_signal", "1");
      window.localStorage.setItem("annual_patent_review", "1");
      window.localStorage.setItem("annual_patent_payment", "1");
      window.localStorage.setItem("annual_patent_receipt", "1");
      window.localStorage.setItem("annual_patent_joint_applicant", "1");
      window.localStorage.setItem("annual_payment_year", "1");
      window.localStorage.setItem("annual_patent_report", "1");
      window.localStorage.setItem("annual_claim", "1");
      window.localStorage.setItem("annual_ownerNames", "1");
      window.localStorage.setItem("annual_per_cost", "1");
    } else {
      setSelectedBasics(false);
    }

    let tab = [
      {
        text: "건수",
        key: "total_number",
        type: null,
        checked:
          window.localStorage.getItem("annual_patent_total_number") == null
            ? 1
            : Number(window.localStorage.getItem("annual_patent_total_number")),
        defaultChecked: 1,
      },
      {
        text: "관리번호",
        key: "manage_number",
        type: 0,
        checked:
          window.localStorage.getItem("annual_patent_manage_number") === null
            ? 0
            : Number(
                window.localStorage.getItem("annual_patent_manage_number")
              ),
        defaultChecked: 0,
      },
      {
        text: "Our Ref.",
        key: "ip_manage_number",
        type: 0,
        checked:
          window.localStorage.getItem("annual_ip_manage_number") === null
            ? 0
            : Number(window.localStorage.getItem("annual_ip_manage_number")),
        defaultChecked: 0,
      },
      {
        text: "Smart5 등급",
        key: "sm_grade",
        type: 0,
        checked:
          window.localStorage.getItem("annual_patent_sm_grade") === null
            ? 0
            : Number(window.localStorage.getItem("annual_patent_sm_grade")),
        defaultChecked: 0,
      },
      {
        text: "NOW 사업성",
        key: "now_grade",
        type: 0,
        checked:
          window.localStorage.getItem("annual_patent_now_grade") === null
            ? 0
            : Number(window.localStorage.getItem("annual_patent_now_grade")),
        defaultChecked: 0,
      },
      {
        text: "Family",
        key: "family",
        type: null,
        checked:
          window.localStorage.getItem("annual_patent_family") == null
            ? 0
            : Number(window.localStorage.getItem("annual_patent_family")),
        defaultChecked: 0,
      },
      {
        text: "국가",
        key: "nation",
        type: 1,
        checked:
          window.localStorage.getItem("annual_patent_nation") == null
            ? 1
            : Number(window.localStorage.getItem("annual_patent_nation")),
        defaultChecked: 1,
      },
      {
        text: "출원번호",
        key: "apply_number",
        type: 17,
        checked:
          window.localStorage.getItem("annual_patent_apply_number") === null
            ? 1
            : Number(window.localStorage.getItem("annual_patent_apply_number")),
        defaultChecked: 1,
      },
      {
        text: "출원일",
        key: "apply_at",
        type: 5,
        checked:
          window.localStorage.getItem("annual_patent_apply_at") == null
            ? 1
            : Number(window.localStorage.getItem("annual_patent_apply_at")),
        defaultChecked: 1,
      },
      {
        text: "등록번호",
        key: "register_number",
        type: 6,
        checked:
          window.localStorage.getItem("annual_patent_register_number") == null
            ? 1
            : Number(
                window.localStorage.getItem("annual_patent_register_number")
              ),
        defaultChecked: 1,
      },
      {
        text: "등록일",
        key: "register_at",
        type: 7,
        checked:
          window.localStorage.getItem("annual_patent_register_at") == null
            ? 1
            : Number(window.localStorage.getItem("annual_patent_register_at")),
        defaultChecked: 1,
      },
      {
        text: "납부기한",
        key: "annual_payment_deadline",
        type: 8,
        checked:
          window.localStorage.getItem(
            "annual_patent_annual_payment_deadline"
          ) == null
            ? 1
            : Number(
                window.localStorage.getItem(
                  "annual_patent_annual_payment_deadline"
                )
              ),
        defaultChecked: 1,
      },
      {
        text: "납부연차",
        key: "payment_year",
        type: 7,
        checked:
          window.localStorage.getItem("annual_payment_year") == null
            ? 1
            : Number(window.localStorage.getItem("annual_payment_year")),
        defaultChecked: 1,
      },
      {
        text: "청구항",
        key: "claim",
        type: null,
        checked:
          window.localStorage.getItem("annual_claim") == null
            ? 1
            : Number(window.localStorage.getItem("annual_claim")),
        defaultChecked: 1,
      },
      {
        text: "금액",
        key: "cost",
        type: 9,
        checked:
          window.localStorage.getItem("annual_patent_cost") == null
            ? 1
            : Number(window.localStorage.getItem("annual_patent_cost")),
        defaultChecked: 1,
      },
      {
        text: "공동출원인",
        key: "joint_applicant",
        type: null,
        checked:
          window.localStorage.getItem("annual_patent_joint_applicant") == null
            ? 1
            : Number(
                window.localStorage.getItem("annual_patent_joint_applicant")
              ),
        defaultChecked: 1,
      },
      {
        text: "권리자",
        key: "ownerNames",
        type: null,
        checked:
          window.localStorage.getItem("annual_patent_ownerNames") == null
            ? 1
            : Number(window.localStorage.getItem("annual_patent_ownerNames")),
        defaultChecked: 1,
      },
      {
        text: "관리주체/비용분담률",
        key: "per_cost",
        type: null,
        checked:
          window.localStorage.getItem("annual_per_cost") == null
            ? 1
            : Number(window.localStorage.getItem("annual_per_cost")),
        defaultChecked: 1,
      },
      {
        text: "납부추천",
        key: "signal",
        type: null,
        checked:
          window.localStorage.getItem("annual_patent_signal") == null
            ? 1
            : Number(window.localStorage.getItem("annual_patent_signal")),
        defaultChecked: 1,
      },
      {
        text: "IP담당자",
        key: "ip_member_name",
        type: null,
        checked:
          window.localStorage.getItem("ip_member_name") == null
            ? 1
            : Number(window.localStorage.getItem("ip_member_name")),
        defaultChecked: 1,
      },
    ];

    if (isShowMail) {
      tab.push({
        text: "메일",
        key: "mail",
        type: null,
        checked:
          window.localStorage.getItem("annual_patent_mail") == null
            ? 1
            : Number(window.localStorage.getItem("annual_patent_mail")),
        defaultChecked: 1,
      });
    }
    if (isShowing) {
      tab.push(
        {
          text: "검토",
          key: "review",
          type: null,
          checked:
            window.localStorage.getItem("annual_patent_review") == null
              ? 1
              : Number(window.localStorage.getItem("annual_patent_review")),
          defaultChecked: 1,
        },
        {
          text: "연차료 포기/납부",
          key: "payment",
          type: null,
          checked:
            window.localStorage.getItem("annual_patent_payment") == null
              ? 1
              : Number(window.localStorage.getItem("annual_patent_payment")),
          defaultChecked: 1,
        },
        {
          text: "비용청구",
          key: "receipt",
          type: 11,
          checked:
            window.localStorage.getItem("annual_patent_receipt") == null
              ? 1
              : Number(window.localStorage.getItem("annual_patent_receipt")),
          defaultChecked: 1,
        }
      );
    }
    if (isShowReport) {
      tab.push({
        text: "특허활용보고서",
        key: "report",
        type: null,
        checked:
          window.localStorage.getItem("annual_patent_report") == null
            ? 1
            : Number(window.localStorage.getItem("annual_patent_report")),
        defaultChecked: 1,
      });
    }
    setBtnsStateTab(tab);
    const no_data = window.$Global.notTd(14, "등록되지 않은 항목 입니다.");

    const config = {
      ...payload,
      count: count,
      page: curPage,
      nation: selectNation.patent_nation,
      past: Number(pastView.patent),
      joint_applicant_idx: selectJoint,
      ip_member_name: selectManager,
      month: month,
      year: currentYear,
      keyword: searchKeyword, // 검색 키워드
      option: searchOption, // 출원번호, 관리번호, 등록번호 등 검색 옵션
    };

    AnnualPay.getPatentList(config).then((res) => {
      if (res.data === false) {
        setPatentTag(no_data);
      } else {
        setIsLoading(false);
        setTotalCount({ ...totalCount, patent_count: res.data.total_count });
        setLast(res.data.last);
        setData(res.data);
        setPatentNationArr(res.data.nation_range);
        setPatentManagerNameArr(res.data.ipMemberRange);
      }
    });

    AnnualPay.getJointApplicantList().then((res) => {
      setPatentJointArr(res.data.joint_applicant_list);
    });
  };

  const getTradeMarkList = () => {
    if (!idx) return;

    const no_data = window.$Global.notTd(14, "등록되지 않은 항목 입니다.");

    const config = {
      ...payload,
      count: count,
      page: curPage,
      nation: "",
      past: Number(pastView.trademark),
      joint_applicant_idx: selectJoint,
      ip_member_name: selectManager,
      month: month,
      year: currentYear,
    };

    AnnualPay.getTradeMarkList(config).then((res) => {
      const tradeMarkData = res.data.items;

      if (res.data === false) {
        setTradeMarkTag(no_data);
      } else {
        setIsLoading(false);
        setTotalCount({ ...totalCount, tradeMark_count: res.data.total_count });
        setLast(res.data.last);
        tradeMarkNationArr.length === 0 &&
          setTradeMarkNationArr(res.data.nation_range);
        if (tradeMarkData.length) {
          const patentArr = tradeMarkData.map((item, idx) => {
            let nation = window.$Global.convertNationCodeToStr(item.nation);
            let color =
              item.signal == 2
                ? "red"
                : item.signal == 1
                ? "yellow"
                : item.signal == 0
                ? "green"
                : "gray";
            const redirectFn = () => {
              return redirect(
                item.idx,
                nation,
                item.manage_number,
                item.register_number || item.regist_number,
                item.first_renewal_expiration_deadline,
                "tradeMark"
              );
            };
            return (
              <>
                <tr key={idx}>
                  <td onClick={redirectFn}>
                    {(curPage - 1) * count + idx + 1}
                  </td>
                  <td onClick={redirectFn}>{item.manage_number || ""}</td>
                  <td onClick={redirectFn}>{item.ip_manage_number || ""}</td>
                  <td onClick={redirectFn}>{nation || ""}</td>
                  <td onClick={redirectFn}>{item.apply_number || ""}</td>
                  <td onClick={redirectFn}>
                    {window.$Global.convertDate(item.apply_at) || ""}
                  </td>
                  <td onClick={redirectFn}>
                    {item.register_number || "미등록"}
                  </td>
                  <td onClick={redirectFn}>
                    {window.$Global.convertDate(item.register_at) === "N/A"
                      ? "미등록"
                      : window.$Global.convertDate(item.register_at)}
                  </td>
                  <td onClick={redirectFn}>
                    {window.$Global.convertDate(item.annual_payment_deadline) ||
                      ""}
                  </td>
                  <td onClick={redirectFn}>
                    {item.cost !== null
                      ? window.$Global.numberWithCommas(item.cost) + "원"
                      : ""}
                  </td>
                  <td onClick={redirectFn}>{item.ip_member_name}</td>
                  {isShowing && (
                    <>
                      <td>
                        <button
                          className="button_review"
                          onClick={() => {
                            setShowPopup({
                              first: true,
                              second: false,
                            });
                            setAnnualFeeData({
                              manage_number: item.manage_number,
                              trademark_idx: item.idx,
                            });
                          }}
                        >
                          <AnnualFeeReviewState type={item.review_cnt} />
                        </button>
                      </td>
                      <td className="annual_payment">
                        <PaymentState
                          state={item.payment_state}
                          idx={item.idx}
                          annual_pay_idx={item.annual_pay_idx}
                          showPatent={showPatent}
                        />
                      </td>
                      <td>
                        <AnnualPayReceipt item={item} />
                      </td>
                    </>
                  )}
                </tr>
              </>
            );
          });
          setTradeMarkTag(patentArr);
        } else {
          setTradeMarkTag(no_data);
        }
      }
    });
  };

  const getDesignList = () => {
    if (!idx) return;

    const no_data = window.$Global.notTd(14, "등록되지 않은 항목 입니다.");

    const config = {
      ...payload,
      count: count,
      page: curPage,
      nation: "",
      past: Number(pastView.design),
      joint_applicant_idx: selectJoint,
      ip_member_name: selectManager,
      month: month,
      year: currentYear,
    };

    AnnualPay.getDesignList(config).then((res) => {
      const designData = res.data.items;

      if (res.data === false) {
        setDesignTag(no_data);
      } else {
        setIsLoading(false);
        setTotalCount({ ...totalCount, design_count: res.data.total_count });
        setLast(res.data.last);
        designNationArr.length === 0 &&
          setDesignNationArr(res.data.nation_range);
        if (designData.length) {
          const patentArr = designData.map((item, idx) => {
            let nation = window.$Global.convertNationCodeToStr(item.nation);
            let color =
              item.signal == 2
                ? "red"
                : item.signal == 1
                ? "yellow"
                : item.signal == 0
                ? "green"
                : "gray";
            const redirectFn = () => {
              return redirect(
                item.idx,
                nation,
                item.manage_number,
                item.register_number || item.regist_number,
                item.first_renewal_expiration_deadline,
                "design"
              );
            };
            return (
              <>
                <tr key={idx}>
                  <td onClick={redirectFn}>
                    {(curPage - 1) * count + idx + 1}
                  </td>
                  <td onClick={redirectFn}>{item.manage_number || ""}</td>
                  <td onClick={redirectFn}>{item.ip_manage_number || ""}</td>
                  <td onClick={redirectFn}>{nation || ""}</td>
                  <td onClick={redirectFn}>{item.apply_number || ""}</td>
                  <td onClick={redirectFn}>
                    {window.$Global.convertDate(item.apply_at) || ""}
                  </td>
                  <td onClick={redirectFn}>
                    {item.register_number || "미등록"}
                  </td>
                  <td onClick={redirectFn}>
                    {window.$Global.convertDate(item.register_at) === "N/A"
                      ? "미등록"
                      : window.$Global.convertDate(item.register_at)}
                  </td>
                  <td onClick={redirectFn}>
                    {window.$Global.convertDate(item.annual_payment_deadline) ||
                      ""}
                  </td>
                  <td onClick={redirectFn}>
                    {item.cost !== null
                      ? window.$Global.numberWithCommas(item.cost) + "원"
                      : ""}
                  </td>
                  <td onClick={redirectFn}>{item.ip_member_name}</td>
                  {isShowing && (
                    <>
                      <td>
                        <button
                          className="button_review"
                          onClick={() => {
                            setShowPopup({
                              first: true,
                              second: false,
                            });
                            setAnnualFeeData({
                              manage_number: item.manage_number,
                              design_idx: item.idx,
                            });
                          }}
                        >
                          <AnnualFeeReviewState type={item.review_cnt} />
                        </button>
                      </td>
                      <td className="annual_payment">
                        <PaymentState
                          state={item.payment_state}
                          idx={item.idx}
                          annual_pay_idx={item.annual_pay_idx}
                          showPatent={showPatent}
                        />
                      </td>
                      <td>
                        <AnnualPayReceipt item={item} />
                      </td>
                    </>
                  )}
                </tr>
              </>
            );
          });
          setDesignTag(patentArr);
        } else {
          setDesignTag(no_data);
        }
      }
    });
  };

  // manage_number (관리번호) register_number (등록번호) deadline_at (만료일) type (특허, 상표)
  const redirect = (
    idx,
    nation,
    manage_number,
    register_number,
    deadline_at,
    apply_number,
    document_number,
    company_name,
    type
  ) => {
    let local_deadline_at = "";

    if (deadline_at) {
      local_deadline_at = new Date(deadline_at).getFullYear() + 20;
    } else {
      local_deadline_at = new Date().getFullYear() + 1000;
    }
    history.push(
      `/annual/manage/${idx}?selectYear=${currentYear}&selectMonth=${month}&nation=${
        nation || 0
      }&nation_string=${window.$Global.convertNationCodeToStr(
        nation
      )}&manage_number=${manage_number || ""}&register_number=${
        register_number || ""
      }&apply_number=${apply_number || ""}&document_number=${
        document_number || ""
      }&companyName=${
        company_name || ""
      }&deadline_at=${local_deadline_at}&type=${type}`
    );
  };

  const onChangeNation = (nation, type) => {
    setCurPage(1);
    setSelectNation({ ...selectNation, patent_nation: nation });
    onClickMoreStateDisplay("nation");

    // if (e.target.name === "patent_nation") {
    //   if (e.target.value === "all") {
    //     setSelectNation({ ...selectNation, patent_nation: "" });
    //   } else {
    //     setSelectNation({ ...selectNation, patent_nation: e.target.value });
    //   }
    // } else if (e.target.name === "trademark_nation") {
    //   if (e.target.value === "all") {
    //     setSelectNation({ ...selectNation, patent_nation: "" });
    //   } else {
    //     setSelectNation({ ...selectNation, patent_nation: e.target.value });
    //   }
    // }
  };

  const onChangeJoint = (joint, type) => {
    setCurPage(1);
    setSelectJoint(joint);
    onClickMoreStateDisplay("jointApplicant");
  };

  const onChangeManager = (name, type) => {
    setCurPage(1);
    setSelectManager(name);
    onClickMoreStateDisplay("manager");
    // if (e.target.value === "all") {
    //   setSelectManager("");
    // } else {
    //   setSelectManager(e.target.value);
    // }
    window.sessionStorage.setItem("ip_member_name", name);
  };

  const saveTab = () => {
    let copy_tab = window.$Global.cloneObject(btns_state_tab);
    let copy_arr_tab = Object.keys(copy_tab).map((key) => copy_tab[key]);

    // 기본 탭 설정일때
    if (selectedBasics) {
      window.localStorage.setItem("annual_patent_tab_init", "1");
      copy_arr_tab.forEach((item) => {
        item.checked = item.defaultChecked;
      });
    } else {
      window.localStorage.removeItem("annual_patent_tab_init");
      copy_arr_tab.forEach((item) => {
        window.localStorage.setItem("annual_patent_" + item.key, item.checked);
      });
    }
    setTabState(false);
  };

  const onClickToggleSelectedBasics = () => {
    if (!selectedBasics) {
      let copy_tab = window.$Global.cloneObject(btns_state_tab);
      let copy_arr_tab = Object.keys(copy_tab).map((key) => copy_tab[key]);
      copy_arr_tab.forEach((item) => {
        item.checked = item.defaultChecked;
      });
      setBtnsStateTab(copy_arr_tab);
    }
    setSelectedBasics(!selectedBasics);
  };

  const onChangeTabChecked = (input, text) => {
    let copy_btns_state_tab = btns_state_tab.concat([]);
    var item = copy_btns_state_tab.find((item) => item.text === text);
    item.checked = Number(input.target.checked);
    setBtnsStateTab(copy_btns_state_tab);

    if (item.checked) {
      table_head_tag.push(<th>{item.text}</th>);
      setTableHead(table_head.concat([item.text]));
    } else {
      let findItem = table_head.find((item) => {
        return item === item.text;
      });
      let idx = table_head.indexOf(findItem);
      setTableHead(table_head.splice(idx, 1));
    }
  };

  const onClickMoreStateDisplay = (type) => {
    setMoreTab({ ...moreTab, [type]: !moreTab[type] });
  };

  const onBlur = (type) => {
    if (!mouse) {
      setMoreTab({ ...moreTab, [type]: true });
    }
  };

  btns_state_tab.forEach((item, i) => {
    if (item.checked) {
      if (item.key === "nation") {
        table_head_tag.push(
          <th
            key={i}
            className={moreTab.nation ? "dp_none" : ""}
            onBlur={() => onBlur("nation")}
          >
            <div className="more">
              <span>{item.text}</span>
              <button
                className="icon_more_down_black"
                onClick={() => onClickMoreStateDisplay("nation")}
              />
              <ul
                onMouseEnter={() => setMouse(true)}
                onMouseLeave={() => setMouse(false)}
              >
                <li
                  onClick={() => onChangeNation("", "patent")}
                  onBlur={() => onBlur("nation")}
                >
                  전 체
                </li>
                {patentNationArr?.map((el) => {
                  return (
                    <li
                      onClick={() => onChangeNation(el.nation, "patent")}
                      onBlur={() => onBlur("nation")}
                    >
                      {window.$Global.convertNationCodeToStr(el.nation)}
                    </li>
                  );
                })}
              </ul>
            </div>
          </th>
        );
      } else if (item.key === "joint_applicant") {
        table_head_tag.push(
          <th
            key={i}
            className={moreTab.jointApplicant ? "dp_none" : ""}
            onBlur={() => onBlur("jointApplicant")}
          >
            <div className="more">
              <span>{item.text}</span>
              <button
                className="icon_more_down_black"
                onClick={() => onClickMoreStateDisplay("jointApplicant")}
              />
              <ul
                onMouseEnter={() => setMouse(true)}
                onMouseLeave={() => setMouse(false)}
              >
                <li
                  onClick={() => onChangeJoint("", "patent")}
                  onBlur={() => onBlur("jointApplicant")}
                >
                  전 체
                </li>
                {patentJointArr?.map((el) => {
                  return (
                    <li
                      onClick={() => onChangeJoint(el.idx, "patent")}
                      onBlur={() => onBlur("jointApplicant")}
                    >
                      {el.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </th>
        );
      } else if (item.key === "ip_member_name") {
        table_head_tag.push(
          <th
            key={i}
            className={moreTab.manager ? "dp_none" : ""}
            onBlur={() => onBlur("manager")}
          >
            <div className="more">
              <span>{item.text}</span>
              <button
                className="icon_more_down_black"
                onClick={() => onClickMoreStateDisplay("manager")}
              />
              <ul
                onMouseEnter={() => setMouse(true)}
                onMouseLeave={() => setMouse(false)}
              >
                <li
                  onClick={() => onChangeManager("", "patent")}
                  onBlur={() => onBlur("manager")}
                >
                  전 체
                </li>
                {patentManagerNameArr?.map((el) => {
                  return (
                    <li
                      onClick={() =>
                        onChangeManager(el.ip_member_name, "patent")
                      }
                      onBlur={() => onBlur("manager")}
                    >
                      {el.ip_member_name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </th>
          // <th>
          //   <select
          //     name="trademark_nation"
          //     className="nation_select"
          //     value={selectManager}
          //     onChange={(e) => onChangeManager(e)}
          //   >
          //     <option value="" defaultValue>
          //       IP담당자
          //     </option>
          //     {patentManagerNameArr?.map((el) => {
          //       return (
          //         <option value={el.ip_member_name}>{el.ip_member_name}</option>
          //       );
          //     })}
          //   </select>
          // </th>
        );
      } else if (item.key === "per_cost") {
        table_head_tag.push(
          <th key={i} style={{ width: "150px" }}>
            {item.text}
          </th>
        );
      } else table_head_tag.push(<th key={i}>{item.text}</th>);
    }
    tab_tags.push(
      <CheckBox
        key={i}
        text={item.text}
        checked={item.checked}
        onChangeChecked={onChangeTabChecked}
        disabled={selectedBasics}
      />
    );
  });

  const getMatchManager = (item) => {
    const managementArr = item.management_name?.split(/[\/,]+/) || [];
    const notSpaceCompanyName = window.sessionStorage
      .getItem("company_name")
      .replace(/\s+/g, "");

    // console.log(managementArr, notSpaceCompanyName);

    managementArr.map((el) => {
      return el.replace(/\s+/g, "");
    });

    if (managementArr.includes(notSpaceCompanyName)) {
      return true;
    } else {
      return false;
    }
  };

  const initData = (data) => {
    if (!data) return;

    let selectedBasics_tab = btns_state_tab.filter((item) => item.checked);

    let total_number = selectedBasics_tab.filter(
      (obj) => obj.key === "total_number"
    ).length;
    let manage_number = selectedBasics_tab.filter(
      (obj) => obj.key === "manage_number"
    ).length;
    let ip_manage_number = selectedBasics_tab.filter(
      (obj) => obj.key === "ip_manage_number"
    ).length;
    let sm_grade = selectedBasics_tab.filter(
      (obj) => obj.key === "sm_grade"
    ).length;
    let now_grade = selectedBasics_tab.filter(
      (obj) => obj.key === "now_grade"
    ).length;
    let family = selectedBasics_tab.filter(
      (obj) => obj.key === "family"
    ).length;
    let nation = selectedBasics_tab.filter(
      (obj) => obj.key === "nation"
    ).length;
    let apply_number = selectedBasics_tab.filter(
      (obj) => obj.key === "apply_number"
    ).length;
    let apply_at = selectedBasics_tab.filter(
      (obj) => obj.key === "apply_at"
    ).length;
    let register_number = selectedBasics_tab.filter(
      (obj) => obj.key === "register_number"
    ).length;
    let register_at = selectedBasics_tab.filter(
      (obj) => obj.key === "register_at"
    ).length;
    let annual_payment_deadline = selectedBasics_tab.filter(
      (obj) => obj.key === "annual_payment_deadline"
    ).length;
    let payment_year = selectedBasics_tab.filter(
      (obj) => obj.key === "payment_year"
    ).length;
    let cost = selectedBasics_tab.filter((obj) => obj.key === "cost").length;
    let signal = selectedBasics_tab.filter(
      (obj) => obj.key === "signal"
    ).length;
    let joint_applicant = selectedBasics_tab.filter(
      (obj) => obj.key === "joint_applicant"
    ).length;
    let ip_member_name = selectedBasics_tab.filter(
      (obj) => obj.key === "ip_member_name"
    ).length;
    let ownerNames = selectedBasics_tab.filter(
      (obj) => obj.key === "ownerNames"
    ).length;
    let claim = selectedBasics_tab.filter((obj) => obj.key === "claim").length;
    let perCost = selectedBasics_tab.filter(
      (obj) => obj.key === "per_cost"
    ).length;

    let review, payment, receipt, report, mail;

    if (isShowMail) {
      mail = selectedBasics_tab.filter((obj) => obj.key === "mail").length;
    }
    if (isShowing) {
      review = selectedBasics_tab.filter((obj) => obj.key === "review").length;
      payment = selectedBasics_tab.filter(
        (obj) => obj.key === "payment"
      ).length;
      receipt = selectedBasics_tab.filter(
        (obj) => obj.key === "receipt"
      ).length;
      // 2024.05.02 오류로 인해 추가 작업
      if (isShowReport) {
        report = selectedBasics_tab.filter(
          (obj) => obj.key === "report"
        ).length;
      }
    }

    if (data.length) {
      let arr_tbody = data.map((item, index) => {
        //console.log('item') // 여기
        //console.log(item.idx)
        //console.log(item.review_state);
        let resdata = [];

        let color =
          item.signal == 2
            ? "red"
            : item.signal == 1
            ? "yellow"
            : item.signal == 0
            ? "green"
            : "gray";
        const redirectFn = () => {
          return redirect(
            item.idx,
            item.nation,
            item.manage_number,
            item.register_number || item.regist_number,
            item.first_renewal_expiration_deadline,
            item.apply_number,
            item.document_number,
            item.company_name,
            "patent"
          );
        };
        return (
          <React.Fragment key={index}>
            <tr data-idx={index} className={index === 0 ? "active" : ""}>
              {total_number ? (
                <td onClick={redirectFn}>
                  {(curPage - 1) * count + index + 1}
                </td>
              ) : null}
              {manage_number ? (
                <td className="manage_number" onClick={redirectFn}>
                  {item.manage_number}
                </td>
              ) : null}
              {ip_manage_number ? (
                <td className="ip_manage_number" onClick={redirectFn}>
                  {item.ip_manage_number}
                </td>
              ) : null}
              {sm_grade ? (
                <td className="sm_grade" onClick={redirectFn}>
                  {item.sm_grade}
                </td>
              ) : null}
              {now_grade ? (
                <td className="now_grade" onClick={redirectFn}>
                  {item.now_grade}
                </td>
              ) : null}
              {family ? (
                <td className="external_family" onClick={redirectFn}>
                  {item.family || ""}
                </td>
              ) : null}
              {nation ? (
                <td className="nation_string" onClick={redirectFn}>
                  {item.nationCode || ""}
                </td>
              ) : null}
              {apply_number ? (
                <td onClick={redirectFn}>{item.apply_number}</td>
              ) : null}
              {apply_at ? (
                <td className="apply_at" onClick={redirectFn}>
                  {window.$Global.convertDate(item.apply_at)}
                </td>
              ) : null}
              {register_number ? (
                <td className="register_number" onClick={redirectFn}>
                  {" "}
                  {item.register_number || "미등록"}
                </td>
              ) : null}
              {register_at ? (
                <td className="register_at" onClick={redirectFn}>
                  {window.$Global.convertDate(item.register_at) === "N/A"
                    ? "미등록"
                    : window.$Global.convertDate(item.register_at)}
                </td>
              ) : null}
              {annual_payment_deadline ? (
                <td className="annual_payment_deadline" onClick={redirectFn}>
                  {window.$Global.convertDate(item.annual_payment_deadline) ||
                    ""}
                </td>
              ) : null}
              {payment_year ? (
                <td className="annual_payment_year" onClick={redirectFn}>
                  {item.payment_year || "0"}
                </td>
              ) : null}
              {claim ? <td onClick={redirectFn}>{item.claim || ""}</td> : null}
              {cost ? (
                <td className="cost" onClick={redirectFn}>
                  {item.nation === 0
                    ? item.cost != null
                      ? window.$Global.numberWithCommas(item.cost) + "원"
                      : "0원"
                    : item.cost_internal != null
                    ? window.$Global.numberWithCommas(item.cost_internal) +
                      item.money_symbol
                    : "0" + item.money_symbol}
                </td>
              ) : null}
              {joint_applicant ? (
                <td className="joint_applicant" onClick={redirectFn}>
                  {item.joint_applicant_list.map((el, idx) => {
                    return (
                      <>
                        <p>{el}</p>
                        {idx !== item.joint_applicant_list.length - 1 && <br />}
                      </>
                    );
                  })}
                </td>
              ) : null}
              {ownerNames ? (
                <td className="joint_applicant" onClick={redirectFn}>
                  {item.joint_owner_list?.map((el, idx) => {
                    return (
                      <>
                        <p>{el}</p>
                        {idx !== item.joint_owner_list.length - 1 && <br />}
                      </>
                    );
                  })}
                </td>
              ) : null}
              {perCost ? (
                <td
                  style={{ width: "150px" }}
                  onClick={() => {
                    setAnnualFeeData({
                      ...item,
                      manage_number: item.manage_number,
                      patent_idx: item.idx,
                    });
                    setIsShowPerCost(true);
                  }}
                >
                  <button
                    className={`perCost ${
                      getMatchManager(item) ? "match" : "not_match"
                    }`}
                  >
                    확인하기
                  </button>
                </td>
              ) : null}
              {signal ? (
                <td className="signal" onClick={redirectFn}>
                  <div className={`circle ${color}`} />
                </td>
              ) : null}
              {ip_member_name ? (
                <td onClick={redirectFn}>{item.ip_member_name || ""}</td>
              ) : null}
              {isShowMail && mail ? (
                <td
                  onClick={() => {
                    setIsShowMailPopUp(true);
                    setAnnualFeeData({
                      ...item,
                      patent_idx: item.idx,
                    });
                  }}
                >
                  <i className={`icon_mail_${item.mail_icon_color}`} />
                </td>
              ) : null}
              {isShowing && review ? (
                <td className="review">
                  <button
                    className="button_review"
                    onClick={() => {
                      setShowPopup({
                        first: true,
                        second: false,
                      });
                      setAnnualFeeData({
                        manage_number: item.manage_number,
                        patent_idx: item.idx,
                      });
                    }}
                  >
                    <AnnualFeeReviewState type={item.review_cnt} />
                  </button>
                  {/*<button*/}
                  {/*  className="button_review"*/}
                  {/*  onClick={() => {*/}
                  {/*    setShowPopup({*/}
                  {/*      first: true,*/}
                  {/*      second: false,*/}
                  {/*    });*/}
                  {/*    setAnnualFeeData({*/}
                  {/*      manage_number: item.manage_number,*/}
                  {/*      patent_idx: item.idx,*/}
                  {/*    });*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  검토중*/}
                  {/*</button>*/}
                </td>
              ) : null}
              {isShowing && payment ? (
                <td className="payment">
                  <PaymentState
                    state={item.payment_state}
                    companyList={isShowCompanyIdxList}
                    idx={item.idx}
                    annual_pay_idx={item.annual_pay_idx}
                    showPatent={showPatent}
                  />
                </td>
              ) : null}
              {isShowing && receipt ? (
                <td className="receipt">
                  <AnnualPayReceipt item={item} />
                </td>
              ) : null}
              {isShowReport && report ? (
                <td>
                  <ReportButton
                    item={item}
                    setIsReportShow={setIsReportShow}
                    setAnnualFeeData={setAnnualFeeData}
                    company_idx={company_idx}
                  />
                  {/*{item.pge_idx || item.pge_idx_f ? (
                    <button
                      className={
                        company_idx !== "EBST5JF6MKQZESME"
                          ? "report"
                          : "nonreport"
                      }
                      onClick={() => {
                        if (company_idx !== "EBST5JF6MKQZESME")
                          setIsReportShow(true);
                        setAnnualFeeData({
                          manage_number: item.manage_number,
                          patent_idx: item.idx,
                          nation: item.nation,
                          register_number:
                            item.register_number || item.regist_number,
                          first_renewal_expiration_deadline:
                            item.first_renewal_expiration_deadline,
                          apply_number: item.apply_number,
                          document_number: item.document_number,
                          company_name: item.company_name,
                          family_idx: item.family_idx,
                          type: "patent",
                          year: item.year,
                        });
                      }}
                    >
                      확인하기
                    </button>
                  ) : (
                    "-"
                  )}*/}
                </td>
              ) : null}
            </tr>
          </React.Fragment>
        );
      });
      setPatentTag(arr_tbody);
    } else {
      setPatentTag([window.$Global.notTd(20, "데이터가 없습니다")]);
    }
  };

  return (
    <div id="ExpectaionList">
      <div className="wrap">
        <h2 className="title">
          “{sessionStorage.getItem("company_name")}”의 {currentYear}년 {month}월
          연차료 지급리스트
        </h2>
        <div className="btns">
          <button className="btn_search_name" onClick={downloadPatentlist}>
            엑셀 다운로드
          </button>
          {isShowing && (
            <>
              <button
                className="btn_search_name"
                onClick={() =>
                  history.push({
                    pathname: "/annual/list/review",
                    state: {
                      company_idx: sessionStorage.getItem("company_idx"),
                      year: currentYear,
                      month,
                    },
                  })
                }
              >
                검토/포기 모아보기
              </button>
              <button
                className="btn_search_name"
                onClick={() =>
                  history.push({
                    pathname: `/annual/compare`,
                    state: {
                      company_idx: sessionStorage.getItem("company_idx"),
                      year: currentYear,
                      month,
                    },
                  })
                }
              >
                연차료 비교
              </button>
            </>
          )}
          {tabState ? (
            <>
              <ToggleButtonWithSpan
                text="Tab 기본 설정하기 "
                condition={{ on: "ON", off: "OFF" }}
                selected={selectedBasics}
                toggleSelected={onClickToggleSelectedBasics}
              />
              <button className="btn_tab_setting_save" onClick={saveTab}>
                Tab 설정 저장
              </button>
            </>
          ) : (
            <button
              className="btn_tab_setting"
              onClick={() => setTabState(true)}
            >
              Tab 설정하기
            </button>
          )}
          <button
            className="btn_back"
            onClick={() => {
              window.sessionStorage.removeItem("curPage");
              window.sessionStorage.removeItem("showPatent");
              window.sessionStorage.removeItem("currentYear");
              window.sessionStorage.removeItem("month");
              history.push("/annual/graph");
            }}
          >
            목록으로 돌아가기
          </button>
        </div>
      </div>
      <div className="second_header">
        <div className="select_wrapper">
          <p>연월 변경</p>
          <select value={currentYear} onChange={(e) => onChangeYear(e)}>
            {Array.from({ length: 26 }, (_, i) => nowYear - 10 + i)
              .reverse()
              .map((y) => (
                <option key={y} value={y}>
                  {y}년
                </option>
              ))}
          </select>
          <select value={month} onChange={(e) => onChangeMonth(e)}>
            {Array.from({ length: 12 }, (_, i) => i + 1).map((m) => (
              <option key={m} value={m}>
                {m}월
              </option>
            ))}
          </select>
        </div>
        <div className="second_header_right">
          <div className="search_wrapper">
            <select onChange={(e) => setSearchOption(e.target.value)}>
              {searchSelectOption.map((item) => {
                return (
                  <option key={item.key} value={item.key} name={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            <input
              type="text"
              value={searchKeyword}
              onChange={(e) => setSearchKeyword(e.target.value)}
              onKeyUp={(e) => e.key === "Enter" && handleSearch()}
            />
            <i className="icon_search_blue" onClick={() => handleSearch()} />
          </div>
          <div className="count_wrapper">
            <p>리스트 개수</p>
            <select
              onChange={(e) => {
                setCount(e.target.value);
                setCurPage(1);
              }}
            >
              <option value={10}>10개</option>
              <option value={50}>50개</option>
              <option value={100}>100개</option>
            </select>
          </div>
        </div>
      </div>
      <div className="wrap_tab_btns">{tabState && tab_tags}</div>
      <div className="annual_change_view">
        <p
          className="table_title"
          style={{
            borderBottom: showPatent === "patent" && "3px solid #4593f5",
          }}
          onClick={() => {
            setCurPage(1);
            setShowPatent("patent");
          }}
        >
          특&nbsp; 허
        </p>
        <p
          className="table_title"
          style={{
            borderBottom: showPatent === "trademark" && "3px solid #4593f5",
          }}
          onClick={() => {
            setCurPage(1);
            setShowPatent("trademark");
          }}
        >
          상&nbsp; 표
        </p>
        <p
          className="table_title"
          style={{
            borderBottom: showPatent === "design" && "3px solid #4593f5",
          }}
          onClick={() => {
            setShowPatent("design");
            setCurPage(1);
          }}
        >
          디 자 인
        </p>
      </div>
      {showPatent === "patent" ? (
        <div className="table-box-wrap">
          <div className="wrap_list custom_scroll">
            <table className="list">
              <thead>
                <tr className="no_focus_outline">{table_head_tag}</tr>
              </thead>
              <tbody>{patentTag}</tbody>
            </table>
          </div>
        </div>
      ) : showPatent === "trademark" ? (
        <div className="table-box-wrap">
          <div className="wrap_list custom_scroll">
            <table className="list">
              <thead>
                <tr>
                  <th>건수</th>
                  <th>관리번호</th>
                  <th>Our Ref.</th>
                  <th
                    className={moreTab.nation ? "dp_none" : ""}
                    onBlur={() => onBlur("nation")}
                  >
                    <div className="more">
                      <span>국 가</span>
                      <button
                        className="icon_more_down_black"
                        onClick={() => onClickMoreStateDisplay("nation")}
                      />
                      <ul
                        onMouseEnter={() => setMouse(true)}
                        onMouseLeave={() => setMouse(false)}
                      >
                        <li
                          onClick={() => onChangeNation("", "tradeMark")}
                          onBlur={() => onBlur("nation")}
                        >
                          전 체
                        </li>
                        {tradeMarkNationArr?.map((el) => {
                          return (
                            <li
                              onClick={() =>
                                onChangeNation(el.nation, "tradeMark")
                              }
                              onBlur={() => onBlur("nation")}
                            >
                              {window.$Global.convertNationCodeToStr(el.nation)}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </th>
                  {/*<th>*/}
                  {/*  <select*/}
                  {/*    name="trademark_nation"*/}
                  {/*    className="nation_select"*/}
                  {/*    onChange={(e) => onChangeNation(e)}*/}
                  {/*  >*/}
                  {/*    <option value="all" defaultValue>*/}
                  {/*      국 가*/}
                  {/*    </option>*/}
                  {/*    {tradeMarkNationArr?.map((el) => {*/}
                  {/*      return (*/}
                  {/*        <option value={el.nation}>*/}
                  {/*          {window.$Global.convertNationCodeToStr(el.nation)}*/}
                  {/*        </option>*/}
                  {/*      );*/}
                  {/*    })}*/}
                  {/*  </select>*/}
                  {/*</th>*/}
                  <th>출원번호</th>
                  <th>출원일</th>
                  <th>등록번호</th>
                  <th>등록일</th>
                  <th>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      납부기한
                      <i
                        className={
                          pastView.trademark
                            ? "icon_arrow_up_gray"
                            : "icon_arrow_down_gray"
                        }
                        onClick={() => {
                          setPastView({
                            ...pastView,
                            trademark: !pastView.trademark,
                          });
                        }}
                      />
                    </div>
                  </th>
                  <th>금액</th>
                  <th>IP담당자</th>
                  {/*<th>금액</th>*/}
                  {isShowing && (
                    <>
                      <th>검토</th>
                      <th>연차료 포기/납부</th>
                      <th>비용청구</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>{tradeMarkTag}</tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="table-box-wrap">
          <div className="wrap_list custom_scroll">
            <table className="list">
              <thead>
                <tr>
                  <th>건수</th>
                  <th>관리번호</th>
                  <th>Our Ref.</th>
                  <th
                    className={moreTab.nation ? "dp_none" : ""}
                    onBlur={() => onBlur("nation")}
                  >
                    <div className="more">
                      <span>국 가</span>
                      <button
                        className="icon_more_down_black"
                        onClick={() => onClickMoreStateDisplay("nation")}
                      />
                      <ul
                        onMouseEnter={() => setMouse(true)}
                        onMouseLeave={() => setMouse(false)}
                      >
                        <li
                          onClick={() => onChangeNation("", "design")}
                          onBlur={() => onBlur("nation")}
                        >
                          전 체
                        </li>
                        {designNationArr?.map((el) => {
                          return (
                            <li
                              onClick={() =>
                                onChangeNation(el.nation, "design")
                              }
                              onBlur={() => onBlur("nation")}
                            >
                              {window.$Global.convertNationCodeToStr(el.nation)}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </th>
                  {/*<th>*/}
                  {/*  <select*/}
                  {/*    name="design_nation"*/}
                  {/*    className="nation_select"*/}
                  {/*    onChange={(e) => onChangeNation(e)}*/}
                  {/*  >*/}
                  {/*    <option value="all" defaultValue>*/}
                  {/*      국 가*/}
                  {/*    </option>*/}
                  {/*    {designNationArr?.map((el) => {*/}
                  {/*      return (*/}
                  {/*        <option value={el.nation}>*/}
                  {/*          {window.$Global.convertNationCodeToStr(el.nation)}*/}
                  {/*        </option>*/}
                  {/*      );*/}
                  {/*    })}*/}
                  {/*  </select>*/}
                  {/*</th>*/}
                  <th>출원번호</th>
                  <th>출원일</th>
                  <th>등록번호</th>
                  <th>등록일</th>
                  <th>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      납부기한
                      <i
                        className={
                          pastView.trademark
                            ? "icon_arrow_up_gray"
                            : "icon_arrow_down_gray"
                        }
                        onClick={() => {
                          setPastView({
                            ...pastView,
                            trademark: !pastView.trademark,
                          });
                        }}
                      />
                    </div>
                  </th>
                  <th>금액</th>
                  <th>IP담당자</th>
                  {isShowing && (
                    <>
                      <th>검토</th>
                      <th>연차료 포기/납부</th>
                      <th>비용청구</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>{designTag}</tbody>
            </table>
          </div>
        </div>
      )}

      <Pagination curPage={curPage} lastNum={last} onClick={setCurPage} />
      {showPopup.first && (
        <AnnualFeeReview
          annualFeeData={annualFeeData}
          setShowPopup={setShowPopup}
          showPatent={showPatent}
        />
      )}
      {showPopup.second && (
        <AnnualFeeReviewSecond
          setShowPopup={setShowPopup}
          annualFeeData={annualFeeData}
          showPatent={showPatent}
        />
      )}
      {showPopup.receipt && (
        <AnnualFeeReceipt
          setShowPopup={setShowPopup}
          annualFeeData={annualFeeData}
          showPatent={showPatent}
        />
      )}
      {showGiveUpPop && (
        <AnnualFeeGiveUpConfirm
          setShowGiveUpPop={setShowGiveUpPop}
          annualFeeData={annualFeeData}
        />
      )}
      {isReportShow && (
        <AnnualFeeReport
          setIsReportShow={setIsReportShow}
          patent_idx={annualFeeData.patent_idx}
          family_idx={annualFeeData.family_idx}
          annualFeeData={annualFeeData}
          setIsCompFrameShow={setIsCompFrameShow}
        />
      )}
      {isShowPerCost && (
        <AnnualFeePerCost
          annualFeeData={annualFeeData}
          setShowPopup={setIsShowPerCost}
          isAdmin={false}
          refresh={handleSearch}
        />
      )}
      {isShowMailPopUp && (
        <AnnualFeeMailing
          annualFeeData={annualFeeData}
          setIsShowMailPopUp={setIsShowMailPopUp}
        />
      )}
      {isCompFrameShow && (
        <div className="comp_frame">
          <div className="close">
            <i
              class="icon_exit_gray"
              onClick={() => setIsCompFrameShow(false)}
            ></i>
          </div>

          <iframe
            src={`https://dev.biznavi.co.kr/researchTech/ipnow/patent/${
              window.$Global.convertNationCodeToStr(annualFeeData.nation) ===
              "KR"
                ? annualFeeData.apply_number.replace(/-/g, "")
                : annualFeeData.document_number
            }/detail?nation=${
              annualFeeData.nation === 3
                ? "EP"
                : window.$Global.convertNationCodeToStr(annualFeeData.nation)
            }&companyName=${annualFeeData.company_name}&tab=2`}
            width="100%"
            height="100%"
            title="기업정보 더보기"
          />
        </div>
      )}
      {isLoading && <Loading show={isLoading} />}
    </div>
  );
}

export default AnnualFeeList;
